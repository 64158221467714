import React from 'react';
import "./blog.css";

const Blog = () => {
    return (
        <div>
            <h1>Blog</h1>
        </div>
    )
}
export default Blog