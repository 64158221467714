import React from 'react'

const Shapes = () => {
    return (
        <div className="shapes">
            <svg fill="#ffccdb" 
            width="40px" 
            height="40px" 
            class="shape s1"
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>


            <svg fill="#ffccdb" 
            width="40px" 
            height="40px"
            class="shape s2" 
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>
            
            <svg fill="#ffccdb" 
            width="40px" 
            height="40px"
            class="shape s3" 
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>


            <svg fill="#ffccdb" 
            width="40px" 
            height="40px"
            class="shape s4" 
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>


            <svg fill="#ffccdb" 
            width="40px" 
            height="40px" 
            class="shape s5"
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>

            <svg fill="#ffccdb" 
            width="40px" 
            height="40px" 
            class="shape s6"
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>



            <svg fill="#ffccdb" 
            width="40px" 
            height="40px" 
            class="shape s7"
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>



            <svg fill="#ffccdb" 
            width="40px" 
            height="40px" 
            class="shape s8"
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>




            <svg fill="#ffccdb" 
            width="40px" 
            height="40px" 
            class="shape s9"
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>
                    
            <svg fill="#ffccdb" 
            width="40px" 
            height="40px" 
            class="shape s10"
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>



            <svg fill="#ffccdb" 
            width="40px" 
            height="40px" 
            class="shape s11"
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>


            <svg fill="#ffccdb" 
            width="40px" 
            height="40px" 
            class="shape s12"
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>
            
            <svg fill="#ffccdb" 
            width="40px" 
            height="40px" 
            class="shape s13"
            viewBox="0 0 1024 1024" 
            xmlns="http://www.w3.org/2000/svg" 
            stroke="#ffccdb">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <path d="M606.867 527.324c0-52.874-42.86-95.734-95.734-95.734-52.865 0-95.724 42.862-95.724 95.734s42.859 95.734 95.724 95.734c52.874 0 95.734-42.86 95.734-95.734zm40.96 0c0 75.495-61.199 136.694-136.694 136.694-75.487 0-136.684-61.201-136.684-136.694S435.646 390.63 511.133 390.63c75.495 0 136.694 61.199 136.694 136.694z"></path><path d="M735.472 265.659c118.81 0 215.122 96.312 215.122 215.122 0 72.662-36.376 138.927-94.997 178.411 9.689 24.717 14.767 51.002 14.767 77.937 0 118.81-96.312 215.122-215.122 215.122-54.129 0-105.007-20.233-144.106-55.678-39.091 35.444-89.969 55.678-144.099 55.678-118.81 0-215.122-96.312-215.122-215.122 0-26.935 5.078-53.22 14.767-77.937-58.621-39.484-94.997-105.749-94.997-178.411 0-118.809 96.31-215.122 215.112-215.122 3.384 0 6.778.097 10.214.29C307.5 156.928 399.367 71.683 511.135 71.683c111.774 0 203.636 85.242 214.124 194.266a182.75 182.75 0 0110.214-.29zm-422.006 43.239c-9.78-1.512-18.398-2.279-26.67-2.279-96.18 0-174.152 77.975-174.152 174.162 0 64.026 34.865 121.891 89.921 152.398 9.568 5.302 13.281 17.186 8.432 26.991-11.862 23.988-18.123 50.069-18.123 76.959 0 96.189 77.973 174.162 174.162 174.162 49.727 0 96.007-21.074 128.92-57.403 8.128-8.972 22.224-8.973 30.354-.002 32.925 36.333 79.206 57.405 128.932 57.405 96.189 0 174.162-77.973 174.162-174.162 0-26.89-6.261-52.971-18.123-76.959-4.848-9.805-1.136-21.69 8.432-26.991 55.056-30.507 89.921-88.372 89.921-152.398 0-96.189-77.973-174.162-174.162-174.162-8.269 0-16.875.766-26.66 2.279-12.545 1.939-23.814-7.883-23.606-20.575-.123-97.918-78.015-175.68-174.073-175.68-96.022 0-173.903 77.718-174.161 173.682.307 14.689-10.962 24.512-23.507 22.573z">
                        </path>
                        </g>
                        </svg>
            
        </div>
    )
}
export default Shapes
