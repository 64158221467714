import React from 'react';
import "./testimonials.css";

const Testimonials = () => {
    return (
        <div>
            <h1>Testimonials</h1>
        </div>
    )
}
export default Testimonials