import React from 'react';
import "./about.css";

const About = () => {
    return (
        <div>
            <h1>About</h1>
        </div>
    )
}
export default About