import React from 'react';
import "./contact.css";

const Contact = () => {
    return (
        <div>
            <h1>Contact</h1>
        </div>
    )
}
export default Contact