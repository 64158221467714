import React from 'react';
import "./services.css";

const Services = () => {
    return (
        <div>
            <h1>Services</h1>
        </div>
    )
}
export default Services